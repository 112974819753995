import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Link,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { presentationTitle } from "../pages/TitlePages";

const Style = {
  itemText: {
    margin: 0,
  },
};

const iconItems = [
  {
    title: presentationTitle,
    path: "/",
    icon: <BusinessCenterOutlinedIcon />,
  },
  {
    title: "Artigos",
    path: "/artigos",
    icon: <ArticleOutlinedIcon />,
  },
  {
    title: "Livros",
    path: "/livros",
    icon: <BookOutlinedIcon />,
  },
  {
    title: "Programas",
    path: "/programas",
    icon: <TopicOutlinedIcon />,
  },
  {
    title: "Investimentos",
    path: "/investimentos",
    icon: <CalculateOutlinedIcon />,
  },
];

export default function PageItemList(props) {
  const isDrawerOpen = props.isDrawerOpen;
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;

  function redirectToPath(path) {
    navigate(path);
  }

  return (
    <List>
      {iconItems.map((iconItem) => (
        <ListItem
          key={iconItem.title}
          disablePadding
          sx={{ display: "block" }}
          to={iconItem.path}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isDrawerOpen ? "initial" : "center",
              px: 2.5,
            }}
            selected={iconItem.path === path}
            onClick={() => {
              redirectToPath(iconItem.path);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isDrawerOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {iconItem.icon}
            </ListItemIcon>
            <ListItemText
              primary={
                <p className="global-font" style={Style.itemText}>
                  {iconItem.title}
                </p>
              }
              component={Link}
              to={iconItem.path}
              selected={iconItem.path === path}
              sx={{ opacity: isDrawerOpen ? 1 : 0 }}
              onClick={() => {
                redirectToPath(iconItem.path);
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
