import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Button, Card, CardContent } from "@mui/material";
import {
  Book2Image,
  BrazilImage,
  BwnImage,
  CadaMinutoImage,
  CaiiteImage,
  Data4GoodImage,
  EstacioImage,
  FacimaImage,
  FatmImage,
  FllImage,
  GdgImage,
  Google2Image,
  HandTalkImage,
  HuaweiImage,
  IfalImage,
  InternetImage,
  JavaImage,
  JuniorAchievementImage,
  LarSaoDomingosImage,
  LeituraImage,
  MediumImage,
  OatImage,
  ObrImage,
  PicPayImage,
  PortoImage,
  RogaDXImage,
  SenaiImage,
  StanboroughSchoolImage,
  StantImage,
  SuprifitasImage,
  Toastmasters2Image,
  UdemyImage,
  Unit2Image,
  UsBrazilConnectImage,
  VentuirImage,
  YoutubeImage,
  ZupImage,
} from "../../../images/Images";
import { ButtonStyle } from "../../../theme/ButtonStyles";
import { openNewTab } from "../../ext/WindowExtensions";

const Styles = {
  container: {
    marginTop: "32px",
    marginBottom: "56px",
    marginRight: "5%",
    marginLeft: "5%",
  },
  timelineDot: {
    padding: 0,
    width: "30px",
    height: "30px",
    borderWidth: 0,
    boxShadow: "none",
    backgroundColor: "white",
  },
  bottomTimelineConnector: {
    width: "1px",
    backgroundColor: "#E5E7EB",
  },
  topTimelineConnector: {
    maxHeight: "16px",
    width: "1px",
    backgroundColor: "#E5E7EB",
  },
  opositeContent: {
    marginTop: "24px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#9ca3af",
  },
  timelineTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#9ca3af",
  },
  cardContent: {
    padding: 12,
  },
  textContainerLeft: {
    textAlign: "left",
  },
};

function HistoryTimelineCard(props) {
  const image = props.image;
  const content = props.content;

  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={Styles.opositeContent}
      ></TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector style={Styles.topTimelineConnector} />
        <HistoryTimelineImage image={image} />
        <TimelineConnector style={Styles.bottomTimelineConnector} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        <Card variant="outlined" sx={{ height: "100%" }}>
          <CardContent style={Styles.cardContent}>{content}</CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}

function HistoryTimelineImage(props) {
  return (
    <div>
      <TimelineDot style={Styles.timelineDot}>
        <img
          src={props.image}
          alt="Event"
          style={{ width: "100%", height: "100%", borderRadius: "50%" }}
        />
      </TimelineDot>
    </div>
  );
}

export default function HistoryTimelineContainer() {
  return (
    <div
      style={Styles.container}
      className="global-font-div global-font-spaccing"
    >
      <Timeline position="alternate">
        <HistoryTimelineCard
          image={PortoImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>Outubro de 2024 - Atual</p>
              <p>Porto</p>
              <strong>Lead Software Engineer</strong>
              <p style={Styles.timelineContent}>
                Engenheiro de Software líder responsável pela estrutra de PJ.
                <br />
                <br />
                Experiência na infraestrutura de serviços utilizando Banking as
                a Service (BAAS), com integração das camadas para por fim haver
                o correto consumo via front-end.
                <br />
                <br />
                Atualmente à frente de 5 equipes de desenvolvimento, auxiliando,
                documentando e tomando decisões arquiteturais.
                <br />
                <br /> Atuação global:
                <ul>
                  <li>Documentação arquitetural;</li>
                  <li>Soluções de larga escala para as plataforma Mobile;</li>
                  <li>
                    Fechamento, disponibilização e gerenciamento das versões na
                    loja de aplicativos.
                  </li>
                </ul>
                <br /> Skills:
                <br /> Android, iOS, Microserviços, Web/PDC (Conexões),
                Autenticação.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Toastmasters2Image}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>01 de Julho de 2024 - Atual</p>
              <p>Toastmasters International</p>
              <strong>Vice Presidente Educacional</strong>
              <br />
              <br />
              <div>
                <p>
                  Como Vice-Presidente de Educação do Toastmasters, eu trabalho
                  no crescimento e desenvolvimento do nosso clube, facilitando e
                  auxiliando na melhoria das habilidades de oratória e liderança
                  dos membros. Organizo e supervisiono as reuniões, garantindo
                  que os membros tenham oportunidades regulares para praticar e
                  receber feedback construtivo para o desenvolvimento de seus
                  discursos. Colaboro com outros dirigentes do clube para manter
                  um ambiente de aprendizagem acolhedor, personalizando os
                  caminhos educacionais de acordo com os objetivos de cada
                  membro.
                </p>
              </div>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Toastmasters2Image}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>3 de Novembro de 2024</p>
              <strong>Toastmasters - Certificado</strong>
              <p>
                Certificado de conclusão por atingir o <b>nível 2</b> de domínio
                de apresentação.
              </p>
              <p></p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={PortoImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                01 de Fevereiro de 2023 - 30 de Setembro de 2024
              </p>
              <p>Porto</p>
              <strong>Staff Software Engineer</strong>
              <p style={Styles.timelineContent}>
                Engenheiro de Software com especialidade mobile
                <br />
                <br />
                Atuação a frente das squads (Histórico):
                <ul>
                  <li>Autenticação</li>
                  <li>Cross</li>
                  <li>Plataforma</li>
                  <li>Analytics</li>
                  <li>Engajamento</li>
                </ul>
                <br /> Global:
                <ul>
                  <li>Gerenciamento da distribuição de versões</li>
                  <li>Loja de aplicativos</li>
                  <li>Negócio</li>
                  <li>CI/CD</li>
                  <li>Ofuscação</li>
                </ul>
                <br /> Local:
                <br /> Atualmente com uma atuação mais direta no time de
                plataforma, realizando a orientação global e local de estruturas
                criadas para todo o escopo de atuação do projeto.
                <br />
                <br /> Durante o desenvolvimento, uma das principais
                funcionalidades foi a implementação da nova Home do projeto.
                Nesse processo, apliquei uma variedade de design patterns e
                conceitos avançados de concorrência e arquitetura, sempre com
                foco em maximizar a performance e garantir a escalabilidade.
                <br />
                <br /> Atuações diretas em features:
                <br />
                Nova home, Server Driven UI, Warnings, Seguro celular, CRM, CPCP
                (Crédito Pessoal com Cartão Porto), engenharia global, sessão,
                pipeline (CI/CD), Cirque Du Soleil.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={RogaDXImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>31 de Agosto de 2024</p>
              <strong>RogaDX - Certificado</strong>
              <p>
                Partipação no evento Roga Developer Experience <b>2024</b> na
                qualidade de participante.
              </p>
              <br />
              <p>Duração 44 horas</p>
              <p></p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={FacimaImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>28 de Agosto de 2024</p>
              <strong>Facima - Certificado</strong>
              <p>Apresentação com o tema:</p>
              <p>Engenharia de Software e o Mercado de Trabalho</p>
              <br />
              <p>Duração 4 horas</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://speakerdeck.com/gabrielbrasileiro/engenharia-de-software-e-o-mercado-de-trabalho"
                  );
                }}
              >
                Apresentação
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Toastmasters2Image}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>22 de Julho de 2024</p>
              <strong>Toastmasters - Certificado</strong>
              <p>
                Certificado de conclusão por atingir o <b>nível 1</b> de domínio
                de apresentação.
              </p>
              <p></p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={UdemyImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>10 de Julho de 2024</p>
              <strong>Udemy - Certificado</strong>
              <p>iOS 18, Swift UI 6 & Swift 6:</p>
              <p>Build iOS Apps From Scratch</p>
              <br />
              <p>Duração 32.5 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={MediumImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>07 de Maio de 2024</p>
              <strong>Medium - Artigo</strong>
              <p>Pro Android Dev</p>
              <p>
                Inline, noinline, crossinline, reified — Kotlin inline and the
                bytecode magic
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://medium.com/proandroiddev/inline-noinline-crossinline-reified-kotlin-inline-and-the-bytecode-magic-942defcda0d6"
                  );
                }}
              >
                Artigo
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Book2Image}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>04 de Março de 2024</p>
              <strong>E-book</strong>
              <p>
                Guia do investidor iniciante: Fundamentos, estratégias, títulos
                e práticas para iniciantes no mundo dos investimentos
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://drive.google.com/file/d/151K2kjviSjrEpvsGYp6AbntRkcaWoVRA/view?usp=share_link"
                  );
                }}
              >
                Livro
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={EstacioImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>19 de Julho de 2023</p>
              <strong>Universidade Estácio de Sá - Diploma</strong>
              <p>Bacharelado em Sistemas de Informação.</p>
              <p>Instituto Federal ↔ Estácio</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={JavaImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>13 de Fevereiro de 2023</p>
              <strong>Café e Finanças - Aula</strong>
              <p>Segunda turma do Café e Finanças.</p>
              <p>
                Uma aula personalizada para cada tipo de turma em formato
                aberto.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={MediumImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>07 de Janeiro de 2024</p>
              <strong>Medium - Artigo</strong>
              <p>Pro Android Dev</p>
              <p>
                Functional Replacement — Changing the control flow of hard
                structures
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://medium.com/proandroiddev/functional-replacement-changing-the-control-flow-of-hard-structures-d94753760492"
                  );
                }}
              >
                Artigo
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={MediumImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>18 de Outubro de 2023</p>
              <strong>Medium - Artigo</strong>
              <p>
                Functional Replacement — Alterando o fluxo de controle de
                códigos difíceis
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://medium.com/@gabriel.brasileiro/functional-replacement-alterando-o-fluxo-de-controle-de-códigos-difíceis-48daf80e1644"
                  );
                }}
              >
                Artigo
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={RogaDXImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>07 de Outubro de 2023</p>
              <strong>RogaDX - Certificado</strong>
              <p>
                Participação no evento Roga Developer Expirience na qualidde de
                participante e palestrante, com o tema{" "}
                <b>Desenvolvimento Eficaz com Código Legado.</b>
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://speakerdeck.com/gabrielbrasileiro/desenvolvimento-eficaz-com-codigo-legado"
                  );
                }}
              >
                Apresentação
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={GdgImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>Abril de 2017 - Março de 2023</p>
              <p>Google Developers Group Maceió</p>
              <strong>Organizador</strong>
              <p>
                Como organizador do Google Developers Group (GDG), liderei uma
                comunidade local de desenvolvedores apaixonados por aprender e
                compartilhar conhecimentos sobre tecnologias do Google e outras
                inovações na área de tecnologia. Meu papel envolve criar uma
                comunidade colaborativa e inclusiva, oferecendo suporte e
                criando oportunidades de networking e aprendizado para
                desenvolvedores de todos os níveis de experiência. Em 2017,
                organizamos o maior Dev Fest do Brasil, com mais de 1000
                participantes e mais de 25 palestrantes.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={VentuirImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>01 de Março de 2023</p>
              <strong>Ventuir - Certificado</strong>
              <p>
                Participação com a startup Acesse Arq do programa Startup
                Nordeste Alagoas, desenvolvido pelo Sebrae e executado pela
                Ventiur Aceleradora, de 17 de julho de 2022 a 01 de outubro de
                2022. Proposta de valor, MVP, validação, acessando os primeiros
                clientes, validação na prática, inovação e marketing,
                viabilidade financeira, potencial de mercado e pitch.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={PortoImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Abril de 2021 - Fevereiro de 2023
              </p>
              <p>Porto</p>
              <strong>Specialist Software Engineer</strong>
              <p>
                Engenheiro de Software com especialidade android.
                <br />
                <br />
                Features: <br />
                Security; Plataform; Cards; Baking; Facial Biometry; Token;
                Baking Security; Login; Core; Support; Internal libraries.
                <br />
                <br />
                Skills: <br />
                Clean Architecture; Hexagonal; Kotlin, Design Patterns (Facade,
                Observer, Factory, Strategy, Builder, Adapter), Refatoração; CI;
                Jenkins; Jacoco; Detekt; Groovy; Modularização; Koin; Mockk;
                Mockito; Coroutines; Flow; Camera; Annotations; Gradle; Code
                Smells, Test Smells; Unit Tests; Instrumented Tests; Robot
                Pattern.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={JavaImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>13 de Fevereiro de 2023</p>
              <strong>Café e Finanças - Aula</strong>
              <p>Primeira turma do Café e Finanças.</p>
              <p>
                Uma aula personalizada para cada tipo de turma em formato aberto
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={OatImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>14 de Outubro de 2021</p>
              <strong>OAT Solutions - Certificado</strong>
              <p>
                Fundamentos relacionados aos fundamentos de desenvolvimento,
                segurança e operações.
              </p>
              <br />
              <p>Duração 32.5 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={MediumImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>20 de Junho de 2021</p>
              <strong>Medium - Artigo</strong>
              <p>
                Inline, noinline, crossinline, reified — Kotlin inline e a
                mágica dos bytecodes
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://medium.com/@gabriel.brasileiro/inline-noinline-crossinline-reified-kotlin-inline-e-a-mágica-dos-bytecodes-db118c87c6dd"
                  );
                }}
              >
                Artigo
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={PicPayImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Dezembro de 2019 - Abril de 2021
              </p>
              <p>PicPay</p>
              <strong>Android Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Engenheiro de Software Android.
                <br />
                <br /> Trabalhando com Clean Architecture, Java, Kotlin, Design
                Patterns (Facade, Observer, Factory, Strategy...), code smells,
                refatoração, CI, modularização, koin, RXJava2, annotations,
                deeplinks, gradle, testes e testes instrumentados, Firebase.
                <br />
                <br />
                Features trabalhadas: <br />
                PIX, Core, Business Core, Deeplinks, Presentes, Troca de senha,
                Renovação Cadastral, Deeplink Store, Identidade, Cadastro
                Dinâmico, Extrato, Tutorial.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={HuaweiImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>14 de Dezembro de 2020</p>
              <strong>Huawei - Certificação Internacional</strong>
              <p>Certificação técnica para uso das tecnologias 5G.</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={IfalImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>11 de Dezembro de 2020</p>
              <strong>IFAL - Certificado</strong>
              <p>Capacitação em redes 5G</p>
              <br />
              <p>Duração 40 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={IfalImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>06 de Novembro de 2020</p>
              <strong>IFAL - Certificado</strong>
              <p>
                Aplicativo para auxiliar a comunicação entre família, escola e
                clínica que atendem pessoas com autismo
              </p>
              <br />
              <p>Duração 240 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={GdgImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>18 de Outubro de 2020</p>
              <strong>Google Developers Group - Certificado</strong>
              <p>Evento de tecnologia e networking online</p>
              <br />
              <p>Duração 21 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={MediumImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>12 de Abril de 2020</p>
              <strong>Medium - Artigo</strong>
              <p>Entendendo injeção de dependência com Kotlin</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://medium.com/@gabriel.brasileiro/inline-noinline-crossinline-reified-kotlin-inline-e-a-mágica-dos-bytecodes-db118c87c6dd"
                  );
                }}
              >
                Artigo
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={ZupImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Agosto de 2019 - Novembro de 2019
              </p>
              <p>Zup Innovation</p>
              <strong>Android Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Densenvolvedor Android
                <br />
                <br />
                Desenvolvedor Android Trabalhando com componetização, MVVM e a
                Clean Architecture. Desenvolvendo como consultor para um grande
                banco espanhol.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={HandTalkImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Novembro de 2018 - Agosto de 2019
              </p>
              <p>Hand Talk</p>
              <strong>Mobile Developer</strong>
              <p>
                Aplicativos Android e IOS nativos (Java, Kotlin e Swift) OKR
                (Objetivos e Principais Resultados) Líder.
                <br />
                <br />
                Eu tive a experiência de desenvolver com Java, Kotlin e Swift.
                Exeperiência com MVP (Model View Presenter), aplicando o SOLID
                para o código ter uma melhor legibilidade. Em 60% do tempo
                trabalhei com Swift.
                <br />
                <br />
                Na gestão trabalhando com os OKRs, elaborei estratégias baseadas
                nas estatísticas adquiridas com as métricas do aplicativo.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={GdgImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Dezembro de 2017 - Março de 2019
              </p>
              <p>Google Developers Group Maceió</p>
              <strong>Facilitador</strong>
              <p>
                Como facilitador no GDG (Google Developers Group), eu organizava
                eventos e atividades para reunir desenvolvedores interessados em
                tecnologias Google. Criava oportunidades de aprendizado,
                networking e troca de conhecimentos, promovendo um ambiente
                inclusivo e colaborativo. Além disso, apoiava a comunidade
                local, coordenando palestras, workshops e outras iniciativas
                para ajudar os participantes a aprimorarem suas habilidades
                técnicas e profissionais.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={StantImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Junho de 2018 - Novembro de 2018
              </p>
              <p>Stant</p>
              <strong>Android Developer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Desenvolvimento Nativo com Java e Kotlin.
                <br />
                <br />
                Experiência com VIPER, MVP e Componentização. Em uma das tarefas
                desenvolvi parte de uma lib para o processamento de imagens.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={IfalImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Março de 2018 - Novembro de 2018
              </p>
              <p>Instituto Federal de Alagoas</p>
              <strong>Desenvolvedor de Jogos e Designer</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Extensionista em criação de jogos
                <br />
                <br />
                Projeto de extensão realizado com o objetivo de desenvolver um
                jogo com imagens adaptas para crianças altistas.
                <br />
                <br />
                Tecnologias:
                <br /> Lua, CoronaSDK, Android, iOS, Figma.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={BwnImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                Setembro de 2017 - Junho de 2018
              </p>
              <p>BWN Aplicativos</p>
              <strong>Estagiário</strong>
              <p
                style={{ ...Styles.timelineBottom, ...Styles.timelineContent }}
              >
                Desenvolvedor Android e Beck-End com Java.
                <br />
                <br />
                Tecnologias:
                <br /> Atuação com PostgreSQL, SQL, JasperReports, JSF, Tomcat.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Google2Image}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>10 de Maio de 2018</p>
              <strong>Google - Certificado</strong>
              <p>
                Treinamento sobre as novas tecnolodias referentes as tecnologias
                de Cloud do Google.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Data4GoodImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>19 de Abril de 2018</p>
              <strong>Campus Party - Certificado | Reconhecimento</strong>
              <p>
                3º Colocado no Hackaton Data4Good que ocorreu na Campus Party
                Natal.
              </p>
              <p>Dados abertos e Machine Learning.</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={IfalImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>09 de Março de 2018</p>
              <strong>IFAL - Certificado</strong>
              <p>Semana de Integração</p>
              <br />
              <p>Duração 20 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={Unit2Image}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>13 de Maio de 2017</p>
              <strong>Unit - Certificado</strong>
              <p>
                Ministração do minicurso Java e Firebase para ensinar sobre a
                criação de aplicações móveis Android utilizando o Firebase.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={SenaiImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>01 de Janeiro de 2017</p>
              <strong>Senai - Diploma</strong>
              <p>Conclusão do nível técnico em Informática para Internet.</p>
              <br />
              <p>Duração 1.224 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={GdgImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>16 de Dezembro de 2016</p>
              <strong>Google Developers Group - Certificado</strong>
              <p>Workshop Angular 2, Ionic 2 e Firebase:</p>
              <p>
                Construção de aplicações utlizando Angular, Ionic e Firebase.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={GdgImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>16 de Dezembro de 2016</p>
              <strong>Google Developers Group - Certificado</strong>
              <p>GDG DevFest Nordeste 2016</p>
              <p>Participação no evento de networking e carreira.</p>
              <p>Participação no Hackathon</p>
              <br />
              <p>Duração 14 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={BrazilImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>19 de Novembro de 2016</p>
              <strong>
                Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte -
                Certificado
              </strong>
              <p>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em João Pessoa/PB, no período de 10 a 19 de Novembro
                de 2016.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={LarSaoDomingosImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>14 de Junho de 2016</p>
              <strong>Lar São Domingos - Evento</strong>
              <p>Palestra sobre desenvolvimento Android.</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={FatmImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>09 de Junho de 2016</p>
              <strong>Federação Alagoana de Tênis de Mesa - Site</strong>
              <p>Criação da primeira versão do site da FATM.</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab("https://fatm.esp.br");
                }}
              >
                Site
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={GdgImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>Junho de 2016</p>
              <strong>Google Developers Group - Certificado</strong>
              <p>Android for Beginners - Study Jams</p>
              <p>Introdução ao desenvolvimento Android.</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={LeituraImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>26 de Maio de 2016</p>
              <strong>Livraria Leitura - Evento</strong>
              <p>Palestra sobre o sistema operacional Android.</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab("https://www.facebook.com/share/15MKFHLx9m/");
                }}
              >
                Evento
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={ObrImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>Dezembro de 2015</p>
              <strong>Olímpiada Brasileira de Robótica</strong>
              <p>Medalha de honra ao mérito.</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={JuniorAchievementImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>28 de Novembro de 2015</p>
              <strong>Junior Achievement - Certificado | Premiação</strong>
              <p>Pelo sucesso de participação no programa mini empresa.</p>
              <p>Campões do programa através da Mini Empresa Alumiar.</p>
              <br />
              <p>Duração 53 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={BrazilImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>21 de Novembro de 2015</p>
              <strong>
                Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte -
                Certificado
              </strong>
              <p>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em Londrina/PR, no período de 12 a 21 de Novembro de
                2015.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={LeituraImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>17 de Julho de 2015</p>
              <strong>Livraria Leitura - Evento</strong>
              <p>Palestra sobre Android na livraria leitura.</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab("https://www.facebook.com/share/14TsBFmZw2/");
                }}
              >
                Evento
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={FllImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>13 de Dezembro de 2014</p>
              <strong>FLL - Certificado</strong>
              <p>
                Participação na etapa regional da temporada World Class da First
                Lego League.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={UsBrazilConnectImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>10 de Dezembro de 2014</p>
              <strong>US-Brazil Connect - Certificado</strong>
              <p>
                Pela conclusão do Programa Conexão Mundo, realizado entre
                Abril/2014 e setembro/2014.
              </p>
              <br />
              <p>Duração 340 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={BrazilImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>15 de Novembro de 2014</p>
              <strong>
                Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte -
                Certificado
              </strong>
              <p>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em João Pessoa/PB, no período de 06 a 15 de Novembro
                de 2014.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={SuprifitasImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>13 de Outubro de 2014</p>
              <strong>Suprifitas - Evento</strong>
              <p>
                Assessoria através do canal Universo do Android para
                dispositivos móveis Android.
              </p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab("https://www.facebook.com/share/p/15EMVBYRrQ/");
                }}
              >
                Evento
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={CaiiteImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>21 de Agosto de 2014</p>
              <strong>Caiite - Certificado</strong>
              <p>Congresso Acadêmico Integrado de Inovação e Tecnologia.</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={BrazilImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>14 de Setembro de 2013</p>
              <strong>
                Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte -
                Certificado
              </strong>
              <p>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em Natal/RN, no período de 05 a 14 de setembro de
                2013.
              </p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={StanboroughSchoolImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>30 de Junho de 2013</p>
              <strong>Life Intercâmbios - Certificado</strong>
              <p>
                Participação em um curso intensivo de inglês de 60 horas na
                Inglaterra, promovido pela Stanborough Secondary School e LIFE
                Intercâmbios.
              </p>
              <p>Período: 19 a 30 de Junho de 2013.</p>
              <br />
              <p>Duração 60 horas</p>
            </div>
          }
        />
        <HistoryTimelineCard
          image={CadaMinutoImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>24 de Fevereiro de 2013</p>
              <strong>Cada Minuto - Entrevista</strong>
              <p>Tema: Mídias digitais viram desafios para as escolas.</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab(
                    "https://drive.google.com/file/d/1exhDF90hnnvnZ-AHwnrOO2AniNR45XMn/view?usp=sharing"
                  );
                }}
              >
                Jornal
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={InternetImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                29 de Novembto de 2012 - O momento
              </p>
              <strong>Universo do Android - Site</strong>
              <p>Criação do site Universo do Android</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab("http://www.universodoandroid.com");
                }}
              >
                Site
              </Button>
            </div>
          }
        />
        <HistoryTimelineCard
          image={YoutubeImage}
          content={
            <div style={Styles.textContainerLeft}>
              <p style={Styles.timelineTitle}>
                03 de Outubro de 2012 - O momento
              </p>
              <strong>Universo do Android - Youtube</strong>
              <p>O início de tudo: Criação do Canal Universo do Android</p>
              <Button
                style={{ marginTop: "16px" }}
                sx={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  openNewTab("https://www.youtube.com/watch?v=u_p3wMIDOLI");
                }}
              >
                Vídeo
              </Button>
            </div>
          }
        />
      </Timeline>
    </div>
  );
}
