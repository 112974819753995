import * as React from "react";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTitle } from "../state/titleSlicer";
import { presentationTitle } from "./TitlePages";
import PersonProfilePortifolioContainer from "../profile/portifolio/PersonProfilePortifolioContainer";

export default function PresentationPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(presentationTitle));
  });

  return (
    <div>
      <PersonProfilePortifolioContainer />
    </div>
  );
}
