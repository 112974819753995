import { AccordionDetails, Button, Divider } from "@mui/material";
import { Accordion, AccordionSummary } from "../../ext/AccordionExtensions";
import { AccordionItem } from "../../cards/AccordionItem";
import { openNewTab } from "../../ext/WindowExtensions";
import { ButtonStyle } from "../../../theme/ButtonStyles";
import { ToastmastersImage, UdemyImage } from "../../../images/Images";

const Style = {
  divider: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  extras: {
    marginTop: "8px",
  },
};

export function CurriculumCertifies() {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <div sx={{ width: "33%", flexShrink: 0 }}>Certificações</div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <AccordionItem
            image={ToastmastersImage}
            title="Toasmasters Pathways - Liderança Dinâmica - Nível 2"
            subtitle="Concedido por Toastmasters"
            period="Novembro de 2024"
            extras={
              <div>
                <p style={Style.extras}>
                  Certificado de conclusão do nível 2 - Domínio de apresentação.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={ToastmastersImage}
            title="Toasmasters Pathways - Liderança Dinâmica - Nível 1"
            subtitle="Concedido por Toastmasters"
            period="Julho de 2024"
            extras={
              <div>
                <p style={Style.extras}>
                  Certificado de conclusão do nível 1 - Domínio de apresentação.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/roga.png")}
            title="Roga DX 2024"
            subtitle="Concedido por ROGA"
            period="31 de Agosto de 2024"
            extras={
              <div>
                <p style={Style.extras}>
                  Participação no evento Roga Developer Experience na qualidde
                  de participante.
                  <br />
                  Carga horária de 44 horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/facima.png")}
            title="Engenharia de Software e o Mercado de Trabalho"
            subtitle="Concedido por FACIMA"
            period="28 de Agosto de 2024"
            extras={
              <div>
                <p style={Style.extras}>
                  Apresentação sobre o mercado de trabalho de TI. <br />
                  Carga horária de 4 horas.
                </p>
                <Button
                  style={Style.extras}
                  sx={ButtonStyle}
                  variant="outlined"
                  onClick={() => {
                    openNewTab(
                      "https://speakerdeck.com/gabrielbrasileiro/engenharia-de-software-e-o-mercado-de-trabalho"
                    );
                  }}
                >
                  Apresentação
                </Button>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={UdemyImage}
            title="iOS 18, Swift UI 6, & Swift 6"
            subtitle="Concedido por Udemy"
            period="Julho de 2024"
            extras={
              <div>
                <p style={Style.extras}>
                  Duração: 32.5 horas.
                  <br /> iOS 18, Swift UI 6, & Swift 6: Build iOS Apps From
                  Scratch.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/roga.png")}
            title="Roga DX 2023"
            subtitle="Concedido por ROGA"
            period="07 de Outubro de 2023"
            extras={
              <div>
                <p style={Style.extras}>
                  Paritipação no evento Roga Developer Expirience na qualidde de
                  participante e palestrante, com o tema Desenvolvimento Eficaz
                  com Código Legado.
                </p>
                <Button
                  style={Style.extras}
                  sx={ButtonStyle}
                  variant="outlined"
                  onClick={() => {
                    openNewTab(
                      "https://speakerdeck.com/gabrielbrasileiro/desenvolvimento-eficaz-com-codigo-legado"
                    );
                  }}
                >
                  Apresentação
                </Button>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/ventuir.png")}
            title="Programa de aceleração de Startups"
            subtitle="Concedido por Ventuir"
            period="Março de 2023"
            extras={
              <div>
                <p style={Style.extras}>
                  Participação com a startup Acesse Arq do programa Startup
                  Nordeste Alagoas, desenvolvido pelo Sebrae e executado pela
                  Ventiur Aceleradora, de 17 de julho de 2022 a 01 de outubro de
                  2022. <br />
                  Proposta de valor, MVP, validação, acessando os primeiros
                  clientes, validação na prática, inovação e marketing,
                  viabilidade financeira, potencial de mercado e pitch.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/oat.png")}
            title="DevSecOps Fundamentos"
            subtitle="Concedido por OAT Solutions"
            period="14 de Outubro de 2021"
            extras={
              <div>
                <p style={Style.extras}>
                  Fundamentos relacionados aos fundamentos de desenvolvimento,
                  segurança e operações.
                  <br />
                  Carga horária de 20 horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/huawei.png")}
            title="HCIA 5G"
            subtitle="Concedido por Huawei"
            period="15 de Dezembro de 2020"
            extras={
              <div>
                <p style={Style.extras}>
                  Certificação técnica para uso das tecnologias 5G.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/ifal.png")}
            title="Capacitação em redes 5G"
            subtitle="Concedido por IFAL"
            period="11 de Dezembro de 2020"
            extras={
              <div>
                <p style={Style.extras}>
                  Capacitação em redes 5G com cara horária de 40 horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/ifal.png")}
            title="Talk2Autism"
            subtitle="Concedido por IFAL"
            period="06 de Novembro de 2020"
            extras={
              <div>
                <p style={Style.extras}>
                  Aplicativo para auxiliar a comunicação entre família, escola e
                  clínica que atendem pessoas com autismo, com carga horária de
                  250 horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/gdg.png")}
            title="DevFest Brasil 2020"
            subtitle="Concedido por Google Developers Group"
            period="18 de Outubro de 2020"
            extras={
              <div>
                <p style={Style.extras}>
                  Evento de tecnologia e networking com carga horária de 21
                  horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/google.png")}
            title="Cloud OnBoard"
            subtitle="Concedido por Google Cloud"
            period="10 de Maio de 2018"
            extras={
              <div>
                <p style={Style.extras}>
                  Treinamento sobre as novas tecnolodias referentes a cloud do
                  Google.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/ifal.png")}
            title="Semana de Integração"
            subtitle="Concedido por IFAL"
            period="09 de Março de 2018"
            extras={
              <div>
                <p style={Style.extras}>Duração: 20 horas</p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/unit.png")}
            title="Java e Firebase"
            subtitle="Concedido por Unit"
            period="Maio de 2017"
            extras={
              <div>
                <p style={Style.extras}>
                  Ministração de minicurso para ensinar sobre a criação de
                  aplicações móveis Android utilizando o Firebase.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/senai.png")}
            title="Diploma - Técnico em Informática para Internet"
            subtitle="Concedido por SENAI"
            period="Janeiro de 2017"
            extras={
              <div>
                <p style={Style.extras}>
                  Conclusão do nível técnico em Informática para Internet com
                  carga horária de 1.224 horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/gdg.png")}
            title="Workshop Angular 2, Ionic 2 e Firebase"
            subtitle="Concedido por GDG Maceió"
            period="Dezembro de 2016"
            extras={
              <div>
                <p style={Style.extras}>
                  Construção de aplicações utlizando Angular, Ionic e Firebase.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/gdg.png")}
            title="GDG DevFest Nordeste 2016"
            subtitle="Concedido por GDG Maceió"
            period="Dezembro de 2016"
            extras={
              <div>
                <p style={Style.extras}>
                  Participação no evento com duração total de 14 horas.
                  <br />
                  Participação do Hackathon.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/gdg.png")}
            title="Android for Beginners - Study Jams"
            subtitle="Concedido por GDG Maceió e Google Developers"
            period="Junho de 2016"
            extras={
              <div>
                <p style={Style.extras}>
                  Introdução ao desenvolvimento Android.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/juniorachievement.png")}
            title="Programa Miniempresa"
            subtitle="Concedido por Junior Achievement"
            period="28 de Novembro de 2015"
            extras={
              <div>
                <p style={Style.extras}>
                  Pelo sucesso de participação no programa mini empresa com
                  carga horária de 53 horas.
                  <br />
                  Campões do programa através da Mini Empresa Alumiar.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/fll.png")}
            title="First Lego League World Class"
            subtitle="Concedido por FLL"
            period="13 de Dezembro de 2014"
            extras={
              <div>
                <p style={Style.extras}>
                  Participação na etapa regional da temporada World Class da
                  First Lego League.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/caiite.png")}
            title="Caiite 2014"
            subtitle="Concedido pelo evento Caiite"
            period="21 de Agosto de 2014"
            extras={
              <div>
                <p style={Style.extras}>
                  Congresso Acadêmico Integrado de Inovação e Tecnologia.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/stanboroughschool.png")}
            title="Stanborough Secondary School - Life Intercâmbios"
            subtitle="Concedido por Life Intercâmbios"
            period="30 de Junho de 2013"
            extras={
              <div>
                <p style={Style.extras}>
                  Participou de um curso intensivo de inglês de 60 horas em
                  Inglaterra, promovido pela Stanborough Secondary School e LIFE
                  Intercâmbios: 19 a 30 de Junho de 2013.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/usbrazilconnect.png")}
            title="Conexão Mundo - US-Brazil Connect - SESI/SENAI"
            subtitle="Concedido por US-Brazil Connect"
            period="30 de Junho de 2013"
            extras={
              <div>
                <p style={Style.extras}>
                  Pela conclusão do Programa Conexão Mundo, realizado entre
                  Abril/2014 e setembro/2014, com carga horária de 340 horas.
                </p>
              </div>
            }
          />
          <Divider style={Style.divider} />
          <AccordionItem
            image={require("../../../assets/jebs.png")}
            title="Jogos Escolares da Juventude 2016"
            subtitle="Concedido pelo Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte"
            period="19 de Novembro de 2016"
            extras={
              <div>
                <p style={Style.extras}>
                  Participação nos jogos escolares da juventude, etapa nacional,
                  realizada em João Pessoa/PB, no período de 10 a 19 de Novembro
                  de 2016.
                </p>
              </div>
            }
          />
        </div>
        <Divider style={Style.divider} />
        <AccordionItem
          image={require("../../../assets/jebs.png")}
          title="Jogos Escolares da Juventude 2015"
          subtitle="Concedido pelo Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte"
          period="21 de Novembro de 2015"
          extras={
            <div>
              <p style={Style.extras}>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em Londrina/PR, no período de 12 a 21 de Novembro de
                2015.
              </p>
            </div>
          }
        />
        <Divider style={Style.divider} />
        <AccordionItem
          image={require("../../../assets/jebs.png")}
          title="Jogos Escolares da Juventude 2014"
          subtitle="Concedido pelo Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte"
          period="15 de Novembro de 2014"
          extras={
            <div>
              <p style={Style.extras}>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em João Pessoa/PB, no período de 06 a 15 de Novembro
                de 2014.
              </p>
            </div>
          }
        />
        <Divider style={Style.divider} />
        <AccordionItem
          image={require("../../../assets/jebs.png")}
          title="Jogos Escolares da Juventude 2013"
          subtitle="Concedido pelo Comitê Olímpico Brasileiro e Ministério do Esporte do Esporte"
          period="14 de Setembro de 2013"
          extras={
            <div>
              <p style={Style.extras}>
                Participação nos jogos escolares da juventude, etapa nacional,
                realizada em Natal/RN, no período de 05 a 14 de setembro de
                2013.
              </p>
            </div>
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}
