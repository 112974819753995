import { useDispatch } from "react-redux";
import { changeTitle } from "../state/titleSlicer";
import { calculatorTitle } from "./TitlePages";
import { useEffect, useState } from "react";
import { GridCardImage } from "../cards/GridCardImage";
import { Button, Grid2 } from "@mui/material";
import { ButtonStyle } from "../../theme/ButtonStyles";
import WiseWebScrappingScriptDialog from "../investments/scripts/WiseWebScrappingScriptDialog";
import { WiseCardImage } from "../../images/Images";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

export default function InvestmentsPage() {
  const dispatch = useDispatch();
  const [wiseWebScrappingScriptDialog, setWiseWebScrappingScriptDialog] =
    useState(false);

  useEffect(() => {
    dispatch(changeTitle(calculatorTitle));
  });

  return (
    <div style={Styles.container}>
      <Grid2 container spacing={{ xs: 2, md: 3 }}>
        <GridCardImage
          title={"Google Planilhas - Preço do dólar Wise com web scraping"}
          description={
            "Realize a integração do Google Planilhas com o Wise para obter o preço do dólar, através do web scrapping"
          }
          image={WiseCardImage}
          navComponent={
            <div>
              <Button
                onClick={() => {
                  setWiseWebScrappingScriptDialog(true);
                }}
                variant="outlined"
                sx={ButtonStyle}
              >
                Script
              </Button>
              <WiseWebScrappingScriptDialog
                open={wiseWebScrappingScriptDialog}
                handleClose={() => setWiseWebScrappingScriptDialog(false)}
              />
            </div>
          }
        />
      </Grid2>
    </div>
  );
}
