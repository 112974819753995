import { CurriculumTimeline } from "../curriculum/CurriculumTimeline";
import { CurriculumDetails } from "../curriculum/CurriculumDetails";
import { PersonProfileHeaderContext } from "../header/PersonProfileHeaderContext";
import { useState } from "react";
import HistoryTimelineContainer from "../timeline/HistoryTimelineContainer";

const Styles = {
  parent: {
    display: "block",
  },
};

const PersonProfileContainer = () => {
  return (
    <div>
      <CurriculumTimeline />
      <CurriculumDetails />
    </div>
  );
};

export default function PersonProfilePortifolioContainer() {
  const [isChecked, setChecked] = useState(false);

  const handleTimeline = (checked) => {
    setChecked(checked);
  };

  return (
    <div style={Styles.parent}>
      <PersonProfileHeaderContext handleTimeline={handleTimeline} />
      {isChecked ? <HistoryTimelineContainer /> : <PersonProfileContainer />}
    </div>
  );
}
