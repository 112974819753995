import { IconButton } from "@mui/material";
import { PersonHeader } from "./PersonDrawerHeaderDetails";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Style = {
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  drawerController: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
    justifyContent: "flex-end",
  },
  personDrawer: {
    width: "100%",
    alignItems: "center",
  },
};

const PersonDrawerHeader = (props) => {
  const handleCloseDrawer = props.handleCloseDrawer;
  const isDrawerOpen = props.isDrawerOpen;
  const topBarHeightStyle = {
    height: props.topBarHeight,
  };

  return (
    <div>
      <div style={Style.drawerContainer}>
        <div style={{ ...Style.drawerController, ...topBarHeightStyle }}>
          <IconButton onClick={handleCloseDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div style={Style.personDrawer}>
          <PersonHeader isDrawerOpen={isDrawerOpen} />
        </div>
      </div>
    </div>
  );
};

export default PersonDrawerHeader;
