import { ProfileHeaderSwitch } from "../switch/ProfileHeaderSwitch";

const Styles = {
  title: {
    textAlign: "center",
    marginTop: "12px",
    fontWeight: 500,
    fontSize: "24px",
  },
  backgroundImage: {
    display: "inline-block",
    width: "100%",
  },
  userImage: {
    position: "relative",
    width: "25%",
    borderRadius: "50%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-15%",
    border: "5px solid #FFFFFF",
  },
};

export function PersonProfileHeaderContext(props) {
  const handleTimeline = props.handleTimeline;

  return (
    <div>
      <img
        style={Styles.backgroundImage}
        src={require("../../../assets/presentation_background.jpeg")}
        alt=""
      />
      <img
        style={Styles.userImage}
        src={require("../../../assets/person.png")}
        alt=""
      />
      <p className="global-font" style={Styles.title}>
        Gabriel Brasileiro
      </p>
      <ProfileHeaderSwitch handleTimeline={handleTimeline} />
    </div>
  );
}
