import { Button } from "@mui/material";
import { openNewTab } from "../../ext/WindowExtensions";

const Styles = {
  buttonsCotainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "24px",
  },
  buttonSize: {
    height: "24px",
    width: "24px",
    borderRadius: "14px",
  },
  buttonSpacing: {
    marginRight: "8px",
  },
};

export default function PersonProfileSocial() {
  return (
    <div style={Styles.buttonsCotainer}>
      <Button
        variant="outlined"
        sx={{
          borderColor: "#BDBDBD",
          borderRadius: "20px",
          color: "#e5e7eb",
          "&:hover": {
            borderColor: "#c1c4ca",
            backgroundColor: "#f3f4f6",
          },
        }}
        onClick={() => {
          openNewTab("https://github.com/GabrielBrasileiro");
        }}
        style={Styles.buttonSpacing}
      >
        <img
          style={Styles.buttonSize}
          src={require("../../../assets/github.png")}
          alt=""
        ></img>
      </Button>
      <Button
        variant="outlined"
        sx={{
          borderColor: "#BDBDBD",
          borderRadius: "20px",
          color: "#e5e7eb",
          "&:hover": {
            borderColor: "#c1c4ca",
            backgroundColor: "#f3f4f6",
          },
        }}
        onClick={() => {
          openNewTab("https://www.linkedin.com/in/gabrielbrasileiro/");
        }}
      >
        <img
          style={Styles.buttonSize}
          src={require("../../../assets/linkedin.png")}
          alt=""
        ></img>
      </Button>
    </div>
  );
}
