import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  WiseStepFive,
  WiseStepFour,
  WiseStepOne,
  WiseStepThree,
  WiseStepTwo,
} from "../../../images/Images";

const WiseWebScrappingScriptJavaScript = `function getWiseDollarPrice() {
  const url = 'https://wise.com/gb/currency-converter/usd-to-brl-rate';
  const options = {
    muteHttpExceptions: true,
    headers: {
      "Accept": "*/*",
      "Accept-Encoding": "deflate, gzip",
      "User-Agent": "Mozilla/5.0",
    }
  };

  const response = UrlFetchApp.fetch(url, options);
  const html = response.getContentText();
  const regex = /<span class="text-success">([\\d,.]+)<\\/span>/;
  const matches = html.match(regex);
  
  if (matches && matches.length > 1) {
    const exchangeRate = matches[1];
    const exchangeRateFloat = parseFloat(exchangeRate.replace(',', ''));

    return exchangeRateFloat;
  } else {
    cosole.log("Exchange rate not found.");
    return "Error: Exchange rate not found";
  }
}
`;

const WiseWebScrappingScriptDialog = ({ open, handleClose }) => {
  const [codeString] = useState(WiseWebScrappingScriptJavaScript);
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopy = () => {
    navigator.clipboard
      .writeText(codeString)
      .then(() => setCopySuccess("Copied!"))
      .catch(() => setCopySuccess("Failed to copy"));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <div className="global-font">Wise Web Scrapping Script</div>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="global-font">
            <div>
              <h2>Passo 1:</h2>
              <p>
                Realize a criação de App script através do menu extensões da
                planilha.
              </p>
              <img
                src={WiseStepOne}
                alt="Wise Step One"
                style={{
                  maxWidth: "100%",
                  marginBottom: "20px",
                }}
              />
            </div>
            <div>
              <h2>Passo 2:</h2>
              <p>
                Na visão da plataforma a seguir realize a inclusão do script
                abaixo.
              </p>
              <img
                src={WiseStepTwo}
                alt="Wise Step One"
                style={{
                  maxWidth: "100%",
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3 style={{ margin: 0 }}>Script:</h3>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleCopy}
                  color={copySuccess ? "success" : "default"}
                >
                  {copySuccess ? "Copiado!" : "Copiar"}
                </Button>
              </div>
              <SyntaxHighlighter language="javascript" showLineNumbers={true}>
                {WiseWebScrappingScriptJavaScript}
              </SyntaxHighlighter>
            </div>
            <div style={{ marginTop: "30px" }}>
              <h2>Passo 3:</h2>
              <p>
                Realize a execução do script através do botão executar. Nesse
                momento será solicitada a permissão para execução do script.
              </p>
              <img
                src={WiseStepThree}
                alt="Wise Step One"
                style={{
                  maxWidth: "100%",
                }}
              />
            </div>{" "}
            <div style={{ marginTop: "30px" }}>
              <h2>Passo 4:</h2>
              <p>
                Recarregue a página da planilha e insira o nome da função,
                começando com o operador de igualdade (=), na célula.
              </p>
              <img
                src={WiseStepFour}
                alt="Wise Step One"
                style={{
                  maxWidth: "100%",
                }}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <h2>Passo 5:</h2>
              <p>O valor do dólar será exibido na célula.</p>
              <img
                src={WiseStepFive}
                alt="Wise Step One"
                style={{
                  maxWidth: "100%",
                }}
              />
            </div>
            <div style={{ marginTop: "40px" }}>
              <h3>Opcional:</h3>
              <p>
                Caso queira que o valor apareça com o símbolo de R$ (Reais),
                clique em: Formatar &gt; Número &gt; Contabilidade.
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WiseWebScrappingScriptDialog;
